import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

import { setupReactQuill } from "components/react-quill/setupReactQuill";
import Hubspot from "components/Hubspot";

import App from "./App";
import { version } from "../package.json";
import AppErrorBoundary from "./error-boundary";
import "./index.css";
import { LocalizationProvider } from "./localization/LocalizationProvider";
import reportWebVitals from "./reportWebVitals";
import { initSentry } from "./setupSentry";
import { initAnalytics } from "./setupAnalytics";

console.log("Version", version);

initSentry();
initAnalytics();
setupReactQuill();

const InitializeApp: React.FC = () => {
	return (
		<React.StrictMode>
			<LocalizationProvider>
				<StyledEngineProvider injectFirst>
					<AppErrorBoundary>
						<CssBaseline />
						<Hubspot />
						<App />
					</AppErrorBoundary>
				</StyledEngineProvider>
			</LocalizationProvider>
		</React.StrictMode>
	);
};

ReactDOM.render(<InitializeApp />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
